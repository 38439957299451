import React from 'react'
import styled from 'styled-components'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Box, Grid, Snackbar, Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';
import founderDp from "./founderDp.jpg"

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
position: relative;
z-index: 1;
align-items: center;
@media (max-width: 960px) {
    padding: 0px;
}
`

const Wrapper = styled.div`
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
width: 100%;
max-width: 1350px;
padding: 0px 0px 80px 0px;
gap: 12px;
margin-top:50px;
@media (max-width: 960px) {
    flex-direction: column;
}
`

const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const Desc = styled.div`
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    max-width: 600px;
    margin-top:22px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        margin-top: 12px;
        font-size: 16px;
    }
`;


const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`

const ContactInputMessage = styled.textarea`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 12px;
  padding: 12px 16px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`

const ContactButton = styled.input`
  width: 100%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -moz-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(465, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;  
`
export const TextLoop = styled.div`
  font-weight: 600;
  font-size: 70px;
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 68px;
  @media (max-width: 960px) {
    text-align: center;
    font-size: 60px;
  }
  @media (max-width: 640px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
  }
`;

export const Span = styled.span`
  color: ${({ theme }) => theme.primary};
  cursor: pointer;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #33e221, #a81dd8);
  -webkit-background-clip: text;
  cursor: pointer;
`;


const Contact = () => {

  //hooks
  const [open, setOpen] = React.useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_tox7kqs', 'template_nv7k7mj', form.current, 'SybVGsYS52j2TfLbi')
      .then((result) => {
        setOpen(true);
        form.current.reset();
      }, (error) => {
        console.log(error.text);
      });
  }



  return (
    <Container id="contact" >      

        <Wrapper>
        <TextLoop>
        Get
          <Span>
            <Typewriter
              options={{
                strings: "In Touch..",
                autoStart: true,
                loop: true,
              }}
            />
          </Span>
        </TextLoop>
        <Desc>Feel free to reach out to me for any questions?</Desc>
        <Box style={{width:"95vw"}}>
        <Grid container md={12} spacing={5}>
        <Grid item md={6} sm={12}  style={{display:"flex",justifyContent:"center"}}> 
<Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          // border: '1px solid #ccc',
          // borderRadius: '8px',
          padding: '20px',
          width: '90vw',
          height:"300px"
        }}
      >
        <Box
          component="img"
          src={founderDp}
          alt="not found"
          sx={{
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            marginBottom: '16px',
            objectFit: "cover",
          }}
        />
        <Typography variant="h4" style={{fontWeight:700}}> Md Mushfique Alam</Typography>
        <Typography variant="h5" style={{fontWeight:500}}>
        Founder & Managing Director, <br/>Aminurmus Technology Pvt Ltd.
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{fontSize:'18px',textAlign:"center"}}>
        At Aminurmus Technology, our mission is to empower businesses with innovative, cutting-edge technology solutions that drive growth and transformation. We believe in creating meaningful partnerships, where our success is defined by the success of our clients. With a dedicated team of experts, we are committed to delivering excellence in every project, ensuring that our clients stay ahead in an ever-evolving digital landscape. Together, we build the future.
        </Typography>
      </Container>

        </Grid>
        <Grid item md={6} sm={12} style={{margin:"auto"}}>
        <ContactForm ref={form} onSubmit={handleSubmit} style={{margin:"auto"}}>
          <ContactTitle>Contact Us </ContactTitle>
          <ContactInput placeholder="Your Email" name="from_email" />
          <ContactInput placeholder="Your Name" name="from_name" />
          <ContactInput placeholder="Subject" name="subject" />
          <ContactInputMessage placeholder="Message" rows="4" name="message" />
          <ContactButton type="submit" value="Send" />
        </ContactForm>
           </Grid>
        </Grid>
        </Box>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={()=>setOpen(false)}
          message="Email sent successfully!"
          severity="success"
        />
      </Wrapper>
       
      
    </Container>
  )
}

export default Contact