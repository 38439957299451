import DownloadIcon from '@mui/icons-material/Download';
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import jsPDF from 'jspdf';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Logo from './logo.png';
import PaymentQR from './qrPhoto.jpg';
import Sign from "./sign.jpg";


const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 8px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
    &::placeholder{
       padding-left:12px;
    }
`

const ContactButton = styled.input`
  width: 20%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -moz-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(465, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;  
   @media (max-width: 1230px) {
    width: 50%;
    padding: 13px 16px;
  }
    @media (max-width: 640px) {
   width: 50%;
    padding: 13px 16px;
  }
`
export default function Index() {
    const { isfulltime } = useParams();
    const [duration, setDuration] = useState(0)
    const [submitFormError, setSubmitFormError] = useState({
        success: false,
        loading: false,
        error: false,
        isDataFilled: false
    })
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        position: "",
        date: new Date().toLocaleDateString(),
        phone: '',
        payment_link: '',
        transaction_id: "",
        duration: "",
        amount:""
    });
    const [isPaymentBtn,setIsPaymentBtn]=useState(false)
    const [amount,setAmount]=useState(false)
    const [paymentData,setPaymentData]=useState({})
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitFormError.loading) return;
        if (formData?.name?.length == 0 || formData?.email?.length === 0
            || formData?.transaction_id?.length < 3 || formData?.phone?.length < 3 ||
            formData?.position?.length < 3
        ) {
            setSubmitFormError({
                ...submitFormError, isDataFilled: true
            })
            return
        }
        setSubmitFormError({
            ...submitFormError, isDataFilled: false
        })
        try {
            const data = new FormData();
            Object.keys(formData).forEach((key) => {
                data.append(key, formData[key]);
            });
            setSubmitFormError({ ...submitFormError, loading: true })
            const scriptURL = "https://script.google.com/macros/s/AKfycbyEy7MuELEjYS7gucIJSlKoXLdb45JQS_RGeRZokEbp9WYG48C6MTvssKpvED4u8tONzg/exec"
            const res = await fetch(scriptURL, {
                method: 'POST',
                body: data
            });
            setSubmitFormError({ ...submitFormError, loading: false, success: true })
            setIsPaymentBtn(true)
            setPaymentData(formData)
            setFormData({
                name: '',
                email: '',
                position: "",
                date: new Date().toLocaleDateString(),
                phone: '',
                payment_link: '',
                transaction_id: "",
                duration: "",
                amount:""
            })

        } catch (error) {
            setSubmitFormError({ ...submitFormError, loading: false, error: true })
        }
    };
    const handleChange = (e, value) => {
        if (value === "name") {
            setFormData({ ...formData, name: e.target.value })
        } else if (value === "email") {
            setFormData({ ...formData, email: e.target.value })
        } else if (value === "phone") {
            setFormData({ ...formData, phone: e.target.value })
        } else if (value === "position") {
            setFormData({ ...formData, position: e.target.value })
        } else if (value === "transaction") {
            setFormData({ ...formData, transaction_id: e.target.value })
        } 
        else if (value === "paymentLink") {
            setFormData({ ...formData, payment_link: e.target.value })
        }
        else if (value === "duration") {
            setDuration(e.target.value)
            if (e.target.value === 1) {
                setFormData({ ...formData, duration: "2 Months" })
            } else if (e.target.value === 2) {
                setFormData({ ...formData, duration: "3 Months" })
            } else if (e.target.value === 3) {
                setFormData({ ...formData, duration: "6 Months" })
            } else if (e.target.value === 4) {
                setFormData({ ...formData, duration: "Full Time" })
            }

        }else if (value === "amount") {
            setAmount(e.target.value)
            if (e.target.value === 1) {
                setFormData({ ...formData, amount: "349" })
            } else if (e.target.value === 2) {
                setFormData({ ...formData, amount: "499" })
            } else if (e.target.value === 3) {
                setFormData({ ...formData, amount: "999" })
            } else if (e.target.value === 4) {
                setFormData({ ...formData, amount: "2499" })
            }

        }

    }
    const downloadReceipt = () => {
        const doc = new jsPDF();
      
        // Add logo
        doc.addImage(Logo, 'PNG', 10, 10, 30, 30); // Position and size of the logo
      
        // Add institute information (customize the address and other details)
        doc.setFontSize(12);
        doc.text(
          'Aminurmus Technology Pvt Ltd.', 
          doc.internal.pageSize.getWidth() / 2, 
          20, 
          { align: 'center' }
        );
      
        doc.text(
          'Address: Banglore, Karnataka - 560102, India (We Work Remotely)', 
          doc.internal.pageSize.getWidth() / 2, 
          30, 
          { align: 'center' }
        );
      
        // Title of the receipt
        doc.setFontSize(16);
        doc.text('Payment Receipt', doc.internal.pageSize.getWidth() / 2, 50, { align: 'center' });
      
        // Add payment details using formData
        doc.setFontSize(14);
        const margin = 20;
        const lineHeight = 10;
        const lineSpacing = 6; // Extra space between fields and the lines
        
        // Function to draw field with label and bold value
        const drawField = (label, value, yPosition) => {
          // Regular font for label
          doc.setFont(undefined, 'bold');
          doc.text(`${label}:`, margin, yPosition);
      
          // Bold font for value
          doc.setFont(undefined, 'normal');
          doc.text(`${value || 'N/A'}`, margin + 40, yPosition); // Indent the value a bit for clarity
      
          // Draw line below the field
          doc.setFont(undefined, 'normal'); // Revert back to normal for the line
          doc.line(margin, yPosition + 2, doc.internal.pageSize.getWidth() - margin, yPosition + 2);
        };
      
        let yPos = 70; // Start Y position for the fields
        drawField('Name', paymentData.name, yPos);
        yPos += lineHeight + lineSpacing;
        
        drawField('Email', paymentData.email, yPos);
        yPos += lineHeight + lineSpacing;
        
        drawField('Phone Number', paymentData.phone, yPos);
        yPos += lineHeight + lineSpacing;
        
        drawField('Position', paymentData.position, yPos);
        yPos += lineHeight + lineSpacing;
        
        drawField('Duration', paymentData.duration, yPos);
        yPos += lineHeight + lineSpacing;
        
        drawField('Transaction ID', paymentData.transaction_id, yPos);
        yPos += lineHeight + lineSpacing;
        
        drawField('Date', paymentData.date || new Date().toLocaleDateString(), yPos);
        yPos += lineHeight + lineSpacing;
        
        drawField('Amount', paymentData.amount, yPos);
        yPos += lineHeight + lineSpacing;
      
        // Add Signature
        doc.addImage(Sign, 'PNG', margin, yPos, 100, 30); // Add the signature image
        doc.text('Signature', margin, yPos + 40); // Position the signature label
      
        // Save the PDF
        doc.save('receipt.pdf');
      };
      
      
    return (
        <div style={{ textAlign: "center" }} >
            <div style={{ marginTop: "30px" }}>
                <span style={{ margin: "auto", fontSize: "30px", fontWeight: 600 }}>Internship/Full Time Registration Form</span>
            </div>
            <div>
                <ul>
                    <li>Official Offer Letter</li>
                    <li>We will give you <span style={{ fontWeight: 600 }}>training</span> (Training fee applicable)
                    </li>
                    <li> You will get  <span style={{ fontWeight: 600 }}>stipend </span> based on project you complete</li>
                    <li>
                        Increment and Performance Awards with certificates recognizing your achievements</li>
                    <li>Professional Email Address: <span style={{ fontWeight: 600 }}>yourname@aminurmus.com</span></li>
                    <li>Opportunity for <span style={{ fontWeight: 600 }}>Pre-Placement Offer</span> (PPO) based on performance and the packege will be <span style={{ fontWeight: 600 }}>3.6 LPA to 4.25 LPA</span></li>
                    <li><span style={{ fontWeight: 600 }}>Internship Experience Certificate</span> at the end of the program</li>
                    <li>After your payments within 24 hour you will onboard with offer letter with official mail ids. And  you will get project details and in training program.</li>
                    <li style={{ fontWeight: 600 }}>Note- This fee is for training and CRM Team and Documentation for getting client project and all maintanance.</li>
                </ul>
            </div>
            <Box style={{ display: "flex", justifyContent: "center" }}>
                <ContactForm >                    
                    <ContactTitle>Payment Form
                    </ContactTitle>

                    <Box
                        component="img"
                        src={PaymentQR}
                        alt="not found"
                        sx={{
                            width: '200px',
                            height: '200px',
                            borderRadius: '50%',
                            marginBottom: '16px',
                            objectFit: "cover",
                            margin: 'auto'
                        }}
                    />
                    <span style={{ fontWeight: 600 }}>Accountant: Jahidur Rahaman(Do Not Call Accountant)</span>
                    <TextField
                        id="standard-error-helper-text"
                        label="Name*"
                        variant="standard"
                        value={formData?.name}
                        onChange={(e) => handleChange(e, "name")}
                    />
                    <TextField
                        id="standard-error-helper-text"
                        label="Email*"
                        variant="standard"
                        value={formData?.email}
                        onChange={(e) => handleChange(e, "email")}
                    />
                    <TextField
                        id="standard-error-helper-text"
                        label="Phone Number*"
                        variant="standard"
                        value={formData?.phone}
                        onChange={(e) => handleChange(e, "phone")}
                    />
                    <TextField
                        id="standard-error-helper-text"
                        label="Postion*"
                        variant="standard"
                        value={formData?.position}
                        onChange={(e) => handleChange(e, "position")}
                    />
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel id="demo-simple-select-standard-label">Internship Duration/ Full Time*</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={duration}
                            label="Internship Duration/ Full Time*"
                            onChange={(e) => handleChange(e, "duration")}
                        >
                            <MenuItem value={1}>2 Month`s</MenuItem>
                            <MenuItem value={2}>3 Month`s</MenuItem>
                            <MenuItem value={3}>6 Month`s</MenuItem>
                            <MenuItem value={4}>Full Time</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                        <InputLabel id="demo-simple-select-standard-label">Amount *</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={amount}
                            label="Amount*"
                            onChange={(e) => handleChange(e, "amount")}
                        >
                            <MenuItem value={1}>INR 199</MenuItem>
                            <MenuItem value={1}>INR 349</MenuItem>
                            <MenuItem value={2}>INR 499</MenuItem>
                            <MenuItem value={3}>INR 999</MenuItem>
                            <MenuItem value={4}>INR 2499</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="standard-error-helper-text"
                        label="Transaction ID*"
                        variant="standard"
                        value={formData?.transaction_id}
                        onChange={(e) => handleChange(e, "transaction")}
                    />
                    <TextField
                        id="standard-error-helper-text"
                        label="Screenshot (google drive link)"
                        variant="standard"
                        value={formData?.payment_link}
                        onChange={(e) => handleChange(e, "paymentLink")}
                    />

                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        <ContactButton type="submit" value="Pay" onClick={(e) => handleSubmit(e)} style={{ background: submitFormError.loading && "grey", color: submitFormError.loading && 'white' }} />
                    </Box>
                    {
                        submitFormError.success &&
                        <Alert severity="success" color="success">
                            Thank you for your payment Please download receipt. Hr will contact you shortly.
                        </Alert>
                    }
                    {
                        submitFormError.error &&
                        <Alert severity="error" color="error">
                            Something Wrong
                        </Alert>
                    }
                    {
                        submitFormError.isDataFilled &&
                        <Alert severity="error" color="error">
                            Please fill all the fields.
                        </Alert>
                    }
{
                        isPaymentBtn &&
                    <Box>
                        <Button variant="contained" startIcon={<DownloadIcon />} onClick={downloadReceipt}>  Download Receipt
                        </Button>
                    </Box>
                    }
                </ContactForm>

            </Box>
        </div>
    )
}