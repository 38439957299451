import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typewriter from 'typewriter-effect';
import styles from './myStyles.module.css'; 
import company1 from "../../assets/company1.png"
import company2 from "../../assets/company2.png"
import company3 from "../../assets/company3.png"
import company4 from "../../assets/company4.png"
import company5 from "../../assets/company5.png"
import company6 from "../../assets/company6.png"


const ImageCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
}));


const ImageCardComponent = ({ title, image, description }) => (<>
  <div>
    <CardMedia
      component="img"
      image={image}
      alt="Card Image"
      style={{ height: 300}}
    />  
  </div>
  </>
);

export default function App() {
  const cards = [
    { image: company1},
    { image: company2},
    { image: company3},
    { image: company4},
    { image: company5},
    { image: company6},
    
  ];

  return (
    <div id="OurClient" style={{margin:'22px',marginTop:'60px'}}>
        <div className={styles.textLoop}>
            Our
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "Clients ..",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </div>
    <Grid container spacing={4}>
      {cards.map((card, index) => (
        <Grid item xs={12} sm={3} md={4} key={index}>
          <ImageCardComponent image={card.image} description={card.description} title={card.title}/>
        </Grid>
      ))}
    </Grid>
    </div>
  );
}
