import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Typewriter from 'typewriter-effect';
import styles from './myStyles.module.css'; 

// Animation for counting up
const countUp = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

// Animation for hover effect
const popOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  height:600px;
  align-items: center;
  @media (max-width: 1230px) {
    height:900px;
  }
    @media (max-width: 640px) {
    height:950px;
  }
  
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;  
  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    margin-top: 12px;
    font-size: 32px;
  }
`

export const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const AchievementsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
  justify-content: center;
`

const Achievement = styled.div`
  background: ${({ theme }) => theme.card};
  border: 0.1px solid #854CE6;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px;
  text-align: center;
  animation: ${countUp} 1s ease-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    animation: ${popOut} 0.6s ease;
    box-shadow: rgba(23, 92, 230, 0.25) 0px 8px 32px;
  }
  @media (max-width: 768px) {
    padding: 10px;
  }
  @media (max-width: 500px) {
    padding: 8px;
  }
`
export const TextLoop = styled.div`
  font-weight: 600;
  font-size: 70px;
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 68px;
  @media (max-width: 960px) {
    text-align: center;
  }
  @media (max-width: 640px) {
    font-size: 22px;
    line-height: 48px;
  }
`;

export const Span = styled.span`
 -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #33e221, #a81dd8);
  -webkit-background-clip: text;
  cursor: pointer;
`;
const AchievementTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 10px;
  white-space: nowrap;
`

const AchievementValue = styled.p`
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
  margin: 0;
  animation: ${countUp} 2s ease-out forwards;
  @media (max-width: 768px) {
    font-size: 28px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`

// Helper function to animate numbers
const AnimatedNumber = ({ value }) => {
  const [displayValue, setDisplayValue] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = value;
    const duration = 2000; // duration in ms
    const incrementTime = 30; // time for each increment
    const step = Math.ceil(end / (duration / incrementTime));
    
    const timer = setInterval(() => {
      start += step;
      if (start >= end) {
        setDisplayValue(end);
        clearInterval(timer);
      } else {
        setDisplayValue(start);
      }
    }, incrementTime);
  }, [value]);

  return <AchievementValue>{displayValue}+</AchievementValue>;
}

const Skills = () => {
  return (
    <Container id="achievements">
      <Wrapper>      
        <TextLoop className={styles.heading}>
          Our
          <Span>
            <Typewriter
              options={{
                strings: "Achievements..",
                autoStart: true,
                loop: true,
              }}
            />
          </Span>
        </TextLoop>
        <div className={styles.subHeading} >
        Aminurmus Technology Pvt Ltd has rapidly grown into a dynamic force in the IT industry, delivering cutting-edge solutions across web development, mobile app development, data analytics, and more. Our success is marked by a diverse portfolio of completed projects for clients ranging from startups to established enterprises. We take pride in our team's expertise, which includes top professionals trained at premier institutions . Our commitment to quality and innovation has earned us a reputation for excellence, as well as long-term partnerships with clients who trust us to drive their digital transformation. Through our unique project-based compensation model, we've empowered our employees to grow both professionally and financially, setting new benchmarks in the industry. 
        </div>        
        <div style={{width:"95vw",margin:"8px",textAlign:'center', display:'flex',justifyContent:"center"}}>

        <Grid container md={12} spacing={4} lg={12} xs={12}>
          <Grid item md={4} sm={6} lg={3} xs={12} >
          <Achievement>
            <AchievementTitle>Satisfied Clients</AchievementTitle>
            <AnimatedNumber value={299} />
          </Achievement>
          </Grid>
          <Grid item md={4} sm={6} lg={3} xs={12}>
          <Achievement>
            <AchievementTitle>Projects Launched</AchievementTitle>
            <AnimatedNumber value={49}  />
          </Achievement>
          </Grid>
          <Grid item md={4} sm={6} lg={3} xs={12}>
          <Achievement>
            <AchievementTitle>Year Completed</AchievementTitle>
            <AnimatedNumber value={3} />
          </Achievement>
          </Grid>
          <Grid item md={4} sm={6} lg={3} xs={12}>
          <Achievement>
            <AchievementTitle>On Going Project</AchievementTitle>
            <AnimatedNumber value={9} />
          </Achievement>
          </Grid>


        </Grid>
        </div>

      </Wrapper>
    </Container>
  )
}

export default Skills