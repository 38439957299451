// import { Alert, Box, Button, Grid, Modal, TextField, Typography} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from './myStyles.module.css';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import styled from 'styled-components'
import { AuthContex } from '../../contex/AuthContex';
import axios from 'axios';
import {backendApi} from "../../constant"
import {carrerData} from "../../data/carrerData"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`
export default function Index() {
  const [data, setData] = useState(carrerData)
  const [inputFeild, setInputFeild] = useState("")
  const [showData, setShowData] = useState(carrerData)
  const [isEditJob, setIsEditJob] = useState(false)
  const [isAddJob, setIsAddJob] = useState(false)
  const [errorMsg, setErrorMsg] = useState("Something Went Wrong")
  const [isDelete, setIsDelete] = useState({
    modelOpen: false,
    deleteId: ""
  })
  const { isAdmin, setIsAdmin } = useContext(AuthContex)

  const [formData, setFormData] = useState({
    _id: "",
    title: "",
    role: "",
    skills: "",
    stipend: "",
    responsibilities: "",
    location: "",
    isFullTime: false

  });
  const [submitFormError, setSubmitFormError] = useState({
    success: false,
    loading: false,
    error: false,
    isDataFilled: false
  })
  const navigate = useNavigate();  
  const handleChangeInput=(value)=>{
    setInputFeild(value)
    const time = setTimeout(() => {
      if(value.length>0){
        const filterData=data.filter((item)=>item.title.toLowerCase().includes(value.toLowerCase()))
        setShowData(filterData)
      }else{
        setShowData(data)
      }
    }, 2000)
    return ()=>clearTimeout(time)
  }
  const handleInternClick = () => {
    const internData = (data).filter((item) => item.isFullTime === false)
    setShowData(internData)

  }
  const handleFullTimeClick = () => {
    const fullTime = (data).filter((item) => item.isFullTime === true)
    setShowData(fullTime)
  }
  const handleEdit = (item) => {
    setIsEditJob(true)
    console.log(item)
    setFormData(item)
  }
  const handleAdd = (item) => {
    setIsAddJob(true)
  }
  const addedJobRoles = async () => {
    try {
      const { data } = await axios.post(`${backendApi}/carrer/create-job`, {
        token: JSON.parse(localStorage.getItem("token")),
        ...formData
      })
      if (data?.success) {
        setFormData({
          title: "",
          role: "",
          skills: "",
          stipend: "",
          responsibilities: "",
          location: "",
          isFullTime: false
        })
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        window.location.reload()
      } else {
        setErrorMsg(data?.msg)
      }
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  }
  const editedJobRoles = async () => {
    try {
      const { data } = await axios.post(`${backendApi}/carrer/edit-job`, {
        token: JSON.parse(localStorage.getItem("token")),
        ...formData
      })
      if (data?.success) {
        setFormData({
          title: "",
          role: "",
          skills: "",
          stipend: "",
          responsibilities: "",
          location: "",
          isFullTime: false
        })
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        window.location.reload()
      } else {
        setErrorMsg(data?.msg)
      }
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  }

  const deletedJobRoles = async (id) => {
    try {
      const { data } = await axios.post(`${backendApi}carrer/delete-job`, {
        token: JSON.parse(localStorage.getItem("token")),
        id: id
      })
      if (data?.success) {
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        setIsDelete({
          modelOpen: false,
          deleteId: ""
        })
        window.location.reload()
      } else {
        setErrorMsg(data?.msg)
      }

    } catch (error) {
      setErrorMsg(data?.msg)
    }
  }
  const getData = async () => {
    try {
      const { data } = await axios.get(`${backendApi}/carrer/get-jobs`)
      if (data?.success) {
        setData(data?.jobs)
        setShowData(data?.jobs)
      }
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  }
  // useEffect(() => {
  //   getData()
  // }, [])
  return (<>
    <Box className={styles.careerBody}>

      <div className={styles.textLoop}>
        Career Oppurtunity
        <span className={styles.span}>
          <Typewriter
            options={{
              strings: "at Aminurmus Technology..",
              autoStart: true,
              loop: true,
            }}
          />
        </span>
      </div>
      <Box style={{ display: "flex", justifyContent: "center", marginBottom: "22px" }}> <TextField id="outlined-basic" label="Search Job Roles" variant="outlined" className={styles.searchBar} onChange={(e) => handleChangeInput(e.target.value)} /></Box>
      <Box style={{ display: "flex", justifyContent: "center", marginBottom: "22px" }}>
      <Button variant="outlined" className={styles.filterBtn} style={{ color: "white", marginLeft: "22px", padding: "10px 10px" }} onClick={()=>setShowData(data)}>All</Button>

        <Button variant="outlined" className={styles.filterBtn} style={{ color: "white", marginLeft: "22px", padding: "10px 10px" }} onClick={handleInternClick} >InternShip</Button>
        <Button variant="outlined" className={styles.filterBtn} style={{ color: "white", marginLeft: "22px", padding: "10px 10px" }} onClick={handleFullTimeClick}>Full Time</Button>
      </Box>
      {
        isAdmin &&
        <Box><Button variant="outlined" className={styles.filterBtn} style={{ color: "white", marginLeft: "22px", padding: "10px 10px" }} onClick={() => handleAdd()}>Add Job Roles</Button></Box>
      }
      <div style={{ margin: "22px", paddingBottom: "60px" }}>
        {
          (showData).map((item) =>

            <Box style={{ width: "95vw", marginTop: "22px" }}>
              <Grid container md={12} spacing={5}>
                <Grid item md={10}>
                  <Typography variant='h4' style={{ fontWeight: "600" }}> {item.title} ({item.location})
                    {
                      isAdmin && <>
                        <Button variant="outlined" style={{ marginLeft: "12px" }} onClick={() => handleEdit(item)}>Edit</Button>
                        <Button variant="outlined" color="error" style={{ marginLeft: "12px" }} onClick={() => setIsDelete({ modelOpen: true, deleteId: item._id })}>Delete</Button>
                      </>
                    }
                  </Typography>
                  <Typography variant='h6' style={{ fontWeight: "600" }}> Skill: <span style={{ fontSize: "16px", fontWeight: "400" }}>{item.skills}</span></Typography>
                  { item.isFullTime &&<Typography variant='h6' style={{ fontWeight: "600" }}> Stipend: <span style={{ fontSize: "16px", fontWeight: "400" }}>{item.stipend}</span></Typography>}
                  <Typography variant='h6' style={{ fontWeight: "600" }}> Responsibilities: <span style={{ fontSize: "16px", fontWeight: "400" }}>{item.responsibilities}</span></Typography>

                </Grid>
                <Grid item md={2} className={styles.btn}
                >
                  <span onClick={() => navigate(`/applyform/${item.role}/${item.isFullTime}`)} className={styles.btnLink} target="_blank" style={{ width: "115px", height: '40px' }} >Apply</span>
                </Grid>
              </Grid>
              <Modal
                open={isEditJob}
                onClose={() => setIsEditJob(!isEditJob)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: "50vw" }} >
                  <ContactForm >
                    <ContactTitle>Edit Job Roles</ContactTitle>
                    <TextField
                      id="standard-error-helper-text"
                      placeholder="Title"
                      variant="standard"
                      value={formData.title}
                      onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    />
                    <TextField
                      id="standard-error-helper-text"
                      placeholder="Roles"
                      variant="standard"
                      value={formData?.role}
                      onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                    />
                    <TextField
                      id="standard-error-helper-text"
                      placeholder="Skills"
                      variant="standard"
                      value={formData.skills}
                      onChange={(e) => setFormData({ ...formData, skills: e.target.value })}
                    />
                    <TextField
                      id="standard-error-helper-text"
                      placeholder="Stipend"
                      variant="standard"
                      value={formData.stipend}
                      onChange={(e) => setFormData({ ...formData, stipend: e.target.value })}
                    />
                    <TextField
                      id="standard-error-helper-text"
                      placeholder="Location"
                      variant="standard"
                      value={formData.location}
                      onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                    />
                    <TextField
                      id="standard-error-helper-text"
                      placeholder="Is Full Time"
                      variant="standard"
                      value={formData.isFullTime}
                      onChange={(e) => setFormData({ ...formData, isFullTime: e.target.value })}
                    />
                    <TextField
                      id="standard-error-helper-text"
                      placeholder="responsibilities"
                      variant="standard"
                      value={formData.responsibilities}
                      onChange={(e) => setFormData({ ...formData, responsibilities: e.target.value })}
                    />
                    <Button variant="outlined" onClick={() => editedJobRoles()}>Save</Button>
                  </ContactForm>
                  {
                    submitFormError.success &&
                    <Alert severity="success" color="success">
                      Job Roles Edited successfully
                    </Alert>
                  }
                  {
                    submitFormError.error &&
                    <Alert severity="error" color="error">
                      {errorMsg}
                    </Alert>
                  }
                  {
                    submitFormError.isDataFilled &&
                    <Alert severity="error" color="error">
                      Please fill proper data
                    </Alert>
                  }
                </Box>
              </Modal>
            </Box>
          )
        }
      </div>
    </Box>
    <Modal
      open={isAddJob}
      onClose={() => setIsAddJob(!isAddJob)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: "50vw" }} >
        <ContactForm >
          <ContactTitle>Add Job Roles</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Title"
            variant="standard"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Roles"
            variant="standard"
            value={formData?.role}
            onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Skills"
            variant="standard"
            value={formData.skills}
            onChange={(e) => setFormData({ ...formData, skills: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Stipend"
            variant="standard"
            value={formData.stipend}
            onChange={(e) => setFormData({ ...formData, stipend: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Location"
            variant="standard"
            value={formData.location}
            onChange={(e) => setFormData({ ...formData, location: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Is Full Time"
            variant="standard"
            value={formData.isFullTime}
            onChange={(e) => setFormData({ ...formData, isFullTime: e.target.value })}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="responsibilities"
            variant="standard"
            value={formData.responsibilities}
            onChange={(e) => setFormData({ ...formData, responsibilities: e.target.value })}
          />
          <Button variant="outlined" onClick={addedJobRoles}>Add</Button>
        </ContactForm>
        {
          submitFormError.success &&
          <Alert severity="success" color="success">
            Job Roles Added successfully
          </Alert>
        }
        {
          submitFormError.error &&
          <Alert severity="error" color="error">
           {errorMsg}
          </Alert>
        }
        {
          submitFormError.isDataFilled &&
          <Alert severity="error" color="error">
            Please fill proper data
          </Alert>
        }
      </Box>
    </Modal>
    <Modal
      open={isDelete?.modelOpen}
      onClose={() => setIsDelete({ modelOpen: "false", deleteId: "" })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Delete Job Roles
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure want to delete
        </Typography>
        <Button variant="outlined" sx={{ mt: 2 }} onClick={() => deletedJobRoles(isDelete?.deleteId)}>Comferm</Button>

      </Box>
    </Modal>
  </>
  )
}
