import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typewriter from 'typewriter-effect';
import styles from './myStyle.module.css'; 

const ImageCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  border:"2px solid transparent",
  borderRadius:"20px",
  '&:hover .descriptionBox': {
    transform: 'translateY(0)',
  },
}));

const DescriptionBox = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#fff',

  transform: 'translateY(100%)',
  transition: 'transform 0.3s ease-in-out',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const ImageCardComponent = ({ title, image, description }) => (<>
  <ImageCard>
    <CardMedia
      component="img"
      image={image}
      alt="Card Image"
      style={{ height: 300}}
    />
    <DescriptionBox className="descriptionBox">
    <Typography variant="h5" style={{fontWeight:900}}>{title}</Typography>
      <Typography variant="h6" style={{marginTop:"20px"}}>{description}</Typography>
      <div style={{textAlign:"-webkit-center",marginTop:"20px"}}>

      <a   href="mailto:info@aminurmus.com" className={styles.btnLink} target="_blank" style={{width:"115px",height:'40px'}}>Lets Talk</a>
      </div>
    </DescriptionBox>
  </ImageCard>
  </>
);

export default function App() {
  const cards = [
    { image: 'https://img.freepik.com/free-photo/web-design-concept-with-drawings_1134-77.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid', title: 'Web Development', description:" Designing and developing responsive, scalable websites that meet the latest industry standards." },
    { image: 'https://img.freepik.com/free-vector/app-development-banner_33099-1720.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid', title: 'Android/IOS Developement', description: "Building high-performance, user-friendly mobile apps tailored for Android and iOS platforms."},
    { image: 'https://img.freepik.com/free-photo/3d-rendering-biorobots-concept_23-2149524399.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid', title: 'AI/ML Solution', description: "Implementing advanced artificial intelligence and machine learning solutions to drive innovation and efficiency."},
    { image: 'https://img.freepik.com/free-photo/scene-with-business-person-working-futuristic-office-job_23-2151003699.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid', title: 'Data Science', description: "Crafting intuitive and visually appealing user interfaces to enhance user experience."},
    { image: 'https://img.freepik.com/free-photo/representations-user-experience-interface-design_23-2150038912.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid', title: 'UI/UX Designing', description: "Providing thorough testing services to ensure flawless product performance before a successful launch." },
    { image: 'https://img.freepik.com/free-photo/person-working-html-computer_23-2150038840.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid', title: 'Testing/Launching', description:"Boosting your website’s visibility with strategic search engine optimization to increase organic traffic."},
  ];

  return (
    <div id="services" style={{margin:'22px',marginTop:'60px'}}>
        <div className={styles.textLoop}>
            What
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "We Do ?",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </div>
        <div className={styles.discr}>
        At Aminurmus Technology Pvt Ltd, we specialize in delivering innovative digital solutions that drive growth and efficiency. Our expertise spans across Android/iOS Development, AI/ML Solutions, Web Development, UI/UX Design, Testing/Launching, and SEO Optimization. We partner with businesses to create cutting-edge applications, optimize digital experiences, and ensure seamless product launches, all while enhancing online visibility through strategic SEO practices. Our commitment is to provide top.
        </div>
    <Grid container spacing={4}>
      {cards.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ImageCardComponent image={card.image} description={card.description} title={card.title}/>
        </Grid>
      ))}
    </Grid>
    </div>
  );
}