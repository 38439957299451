import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import {backendApi} from '../constant'

const AuthContex=createContext()
const AuthProvider=({children})=>{
    const [isAdmin,setIsAdmin]=useState(false)
    const [isLogin,setisLogin]=useState(false)

    const findIsAdmin=async()=>{
        try {
            const {data}=await axios.post(`${backendApi}/user/is-admin`,{
                token:JSON.parse(localStorage.getItem("token"))
            })
            if(data?.success){
                setIsAdmin(true)
            }
        } catch (error) {
            
        }
    }
    useEffect(()=>{
        findIsAdmin()
        const token=localStorage.getItem("token")
        if(token){
            setisLogin(true)
        }
    },[])
    return <AuthContex.Provider value={{isAdmin,setIsAdmin,isLogin,setisLogin}}>{children}</AuthContex.Provider>
}
export {AuthContex,AuthProvider}