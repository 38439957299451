    export const carrerData = [
        {
            title: "React.js Developer Intern ",
            role:"React.js-Developer-Intern",
            skills: [
              "Strong proficiency in JavaScript and React.js",
              "Understanding of React.js workflows (e.g., Flux or Redux)",
              "Familiarity with RESTful APIs and web services",
              "Basic knowledge of front-end technologies like HTML5 and CSS3",
              "Ability to work in a collaborative, team-oriented environment",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing and implementing user interface components",
              "Optimizing components for performance across web browsers",
              "Collaborating with backend developers and designers",
            ],
            location: "Remote",
            isFullTime:false
          },
       {
            title: "React.js Developer Full Time ",
            role:"React.js-Developer-FullTime",
            skills: [
              "Strong proficiency in JavaScript and React.js",
              "Understanding of React.js workflows (e.g., Flux or Redux)",
              "Familiarity with RESTful APIs and web services",
              "Basic knowledge of front-end technologies like HTML5 and CSS3",
              "Ability to work in a collaborative, team-oriented environment",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing and implementing user interface components",
              "Optimizing components for performance across web browsers",
              "Collaborating with backend developers and designers",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Mern Stack Developer Intern ",
            role:"Mern-Stack-Developer-Intern",
            skills: [
              "Proficiency in MongoDB, Express.js, React, and Node.js (MERN)",
              "Knowledge of full-stack development and web applications",
              "Understanding of RESTful services and API integration",
              "Familiarity with Git and version control",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing web applications using the MERN stack",
              "Working with front-end and back-end development teams",
              "Integrating third-party APIs and databases",
            ],
            location: "Remote",
            isFullTime:false

          },
  {
            title: "Mern Stack Developer FullTime ",
            role:"Mern-Stack-Developer-FullTime",
            skills: [
              "Proficiency in MongoDB, Express.js, React, and Node.js (MERN)",
              "Knowledge of full-stack development and web applications",
              "Understanding of RESTful services and API integration",
              "Familiarity with Git and version control",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing web applications using the MERN stack",
              "Working with front-end and back-end development teams",
              "Integrating third-party APIs and databases",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: ".Net Developer Intern ",
            role:".Net-Developer-Intern",
            skills: [
              "Proficiency in .NET Framework and C#",
              "Familiarity with web technologies (ASP.NET, MVC, Web API)",
              "Knowledge of SQL Server and database design",
              "Understanding of object-oriented programming (OOP)",
              "Good communication and problem-solving skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing and maintaining web applications using .NET technologies",
              "Collaborating with database developers and front-end teams",
              "Debugging and fixing issues in existing applications",
            ],
            location: "Remote",
            isFullTime:false

          },
  {
            title: ".Net Developer FullTime ",
            role:".Net-Developer-FullTime",
            skills: [
              "Proficiency in .NET Framework and C#",
              "Familiarity with web technologies (ASP.NET, MVC, Web API)",
              "Knowledge of SQL Server and database design",
              "Understanding of object-oriented programming (OOP)",
              "Good communication and problem-solving skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing and maintaining web applications using .NET technologies",
              "Collaborating with database developers and front-end teams",
              "Debugging and fixing issues in existing applications",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Figma Development Intern ",
            role:"Figma-Development-Intern",
            skills: [
              "Proficiency in Figma for UI/UX design",
              "Understanding of user interface design principles",
              "Familiarity with web design standards and frameworks",
              "Ability to create interactive prototypes",
              "Strong attention to detail",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Designing and developing UI/UX for web and mobile applications",
              "Creating wireframes, prototypes, and design assets",
              "Collaborating with developers and stakeholders for design approval",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "Full Stack Developer Intern ",
            role:"Full-Stack-Developer-Intern",
            skills: [
              "Proficiency in front-end and back-end technologies (e.g., HTML, CSS, JavaScript, Node.js, React)",
              "Understanding of databases (e.g., MongoDB, SQL)",
              "Familiarity with RESTful APIs and web services",
              "Experience with version control tools like Git",
              "Problem-solving skills and ability to work under pressure",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Building web applications from front to back-end",
              "Developing and managing databases and server-side code",
              "Collaborating with design teams for UI/UX improvements",
            ],
            location: "Remote",
             isFullTime:false

          },

   {
            title: "Full Stack Developer FullTime ",
            role:"Full-Stack-Developer-FullTime",
            skills: [
              "Proficiency in front-end and back-end technologies (e.g., HTML, CSS, JavaScript, Node.js, React)",
              "Understanding of databases (e.g., MongoDB, SQL)",
              "Familiarity with RESTful APIs and web services",
              "Experience with version control tools like Git",
              "Problem-solving skills and ability to work under pressure",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Building web applications from front to back-end",
              "Developing and managing databases and server-side code",
              "Collaborating with design teams for UI/UX improvements",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Frontend Developer Intern ",
            role:"Frontend-Developer-Intern",
            skills: [
              "Proficiency in HTML, CSS, JavaScript, and front-end frameworks (e.g., React, Angular, Vue)",
              "Knowledge of responsive design and mobile-first development",
              "Understanding of browser testing and debugging",
              "Experience with version control (e.g., Git)",
              "Creativity and attention to detail",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing and maintaining user-facing web applications",
              "Ensuring high performance and responsiveness of the website",
              "Working closely with designers to translate designs into code",
            ],
            location: "Remote",
            isFullTime:false

          },
  {
            title: "Frontend Developer FullTime ",
            role:"Frontend-Developer-FullTime",
            skills: [
              "Proficiency in HTML, CSS, JavaScript, and front-end frameworks (e.g., React, Angular, Vue)",
              "Knowledge of responsive design and mobile-first development",
              "Understanding of browser testing and debugging",
              "Experience with version control (e.g., Git)",
              "Creativity and attention to detail",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing and maintaining user-facing web applications",
              "Ensuring high performance and responsiveness of the website",
              "Working closely with designers to translate designs into code",
            ],
            location: "Remote",
            isFullTime:true

          },

          {
            title: "Backend Developer Intern ",
            role:"Backend-Developer-Intern",
            skills: [
              "Proficiency in server-side languages (e.g., Node.js, Python, Java)",
              "Familiarity with databases (e.g., MySQL, MongoDB)",
              "Understanding of API development and integration",
              "Knowledge of security and data protection practices",
              "Experience with cloud services (e.g., AWS, Azure)",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing and managing the server-side logic for web applications",
              "Working with databases and ensuring data integrity",
              "Collaborating with front-end developers to integrate APIs",
            ],
            location: "Remote",
            isFullTime:false

          },

  {
            title: "Backend Developer FullTime ",
            role:"Backend-Developer-FullTime",
            skills: [
              "Proficiency in server-side languages (e.g., Node.js, Python, Java)",
              "Familiarity with databases (e.g., MySQL, MongoDB)",
              "Understanding of API development and integration",
              "Knowledge of security and data protection practices",
              "Experience with cloud services (e.g., AWS, Azure)",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing and managing the server-side logic for web applications",
              "Working with databases and ensuring data integrity",
              "Collaborating with front-end developers to integrate APIs",
            ],
            location: "Remote",
            isFullTime:true

          },

          {
            title: "C/C++ Developer Intern ",
            role:"C-C++-Developer-Intern",
            skills: [
              "Strong proficiency in C and C++ programming languages",
              "Understanding of algorithms, data structures, and OOP concepts",
              "Familiarity with memory management and multi-threading",
              "Knowledge of low-level system architecture",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing software using C and C++",
              "Testing and debugging applications for efficiency",
              "Collaborating with cross-functional teams for software development",
            ],
            location: "Remote",
             isFullTime:false

          },
  {
            title: "C/C++ Developer FullTime ",
            role:"C/C++-Developer-FullTime",
            skills: [
              "Strong proficiency in C and C++ programming languages",
              "Understanding of algorithms, data structures, and OOP concepts",
              "Familiarity with memory management and multi-threading",
              "Knowledge of low-level system architecture",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing software using C and C++",
              "Testing and debugging applications for efficiency",
              "Collaborating with cross-functional teams for software development",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Software Development Intern ",
            role:"Software-Development-Intern",
            skills: [
              "Proficiency in at least one programming language (e.g., Java, Python, C++)",
              "Understanding of software development life cycle",
              "Familiarity with databases and web technologies",
              "Problem-solving and critical thinking abilities",
              "Strong collaboration and teamwork skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Designing and developing software applications",
              "Testing and debugging code to ensure functionality",
              "Working with teams to improve and optimize existing applications",
            ],
            location: "Remote",
            isFullTime:false

          },

     {
            title: "Software Development FullTime ",
            role:"Software-Development-FullTime",
            skills: [
              "Proficiency in at least one programming language (e.g., Java, Python, C++)",
              "Understanding of software development life cycle",
              "Familiarity with databases and web technologies",
              "Problem-solving and critical thinking abilities",
              "Strong collaboration and teamwork skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Designing and developing software applications",
              "Testing and debugging code to ensure functionality",
              "Working with teams to improve and optimize existing applications",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Embedded Systems Developer Intern ",
            role:"Embedded-Systems-Developer-Intern",
            skills: [
              "Proficiency in C, C++, and assembly language",
              "Familiarity with microcontrollers and embedded systems",
              "Understanding of hardware-software integration",
              "Knowledge of real-time operating systems (RTOS)",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing embedded systems software for hardware platforms",
              "Testing and debugging embedded applications",
              "Collaborating with hardware teams to ensure seamless integration",
            ],
            location: "Remote",
            isFullTime:false

          },

   {
            title: "Embedded Systems Developer FullTime ",
            role:"Embedded-Systems-Developer-FullTime",
            skills: [
              "Proficiency in C, C++, and assembly language",
              "Familiarity with microcontrollers and embedded systems",
              "Understanding of hardware-software integration",
              "Knowledge of real-time operating systems (RTOS)",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing embedded systems software for hardware platforms",
              "Testing and debugging embedded applications",
              "Collaborating with hardware teams to ensure seamless integration",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Android Developer Intern",
            role:"Android-Developer-Intern",
            skills: [
              "Proficiency in Java and Kotlin for Android development",
              "Familiarity with Android SDK and Android Studio",
              "Knowledge of RESTful APIs and third-party libraries",
              "Understanding of mobile UI/UX design principles",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing Android applications with high performance",
              "Testing and optimizing mobile applications for various devices",
              "Collaborating with designers and backend developers",
            ],
            location: "Remote",
            isFullTime:false

          },

  {
            title: "Android Developer Full Time",
            role:"Android-Developer-FullTime",
            skills: [
              "Proficiency in Java and Kotlin for Android development",
              "Familiarity with Android SDK and Android Studio",
              "Knowledge of RESTful APIs and third-party libraries",
              "Understanding of mobile UI/UX design principles",
              "Problem-solving and debugging skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing Android applications with high performance",
              "Testing and optimizing mobile applications for various devices",
              "Collaborating with designers and backend developers",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Java Developer Intern ",
            role:"Java-Developer-Intern",
            skills: [
              "Proficiency in Java programming language",
              "Understanding of OOP principles and design patterns",
              "Familiarity with Java frameworks (e.g., Spring, Hibernate)",
              "Knowledge of databases and SQL",
              "Strong problem-solving skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing server-side applications using Java",
              "Collaborating with teams for project development",
              "Testing and optimizing Java applications",
            ],
            location: "Remote",
            isFullTime:false

          },
  {
            title: "Java Developer FullTime ",
            role:"Java-Developer-FullTime",
            skills: [
              "Proficiency in Java programming language",
              "Understanding of OOP principles and design patterns",
              "Familiarity with Java frameworks (e.g., Spring, Hibernate)",
              "Knowledge of databases and SQL",
              "Strong problem-solving skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing server-side applications using Java",
              "Collaborating with teams for project development",
              "Testing and optimizing Java applications",
            ],
            location: "Remote",
            isFullTime:true

          },

          {
            title: "Python Developer Intern ",
            role:"Python-Developer-Intern",
            skills: [
              "Proficiency in Python programming language",
              "Familiarity with Python frameworks (e.g., Django, Flask)",
              "Understanding of databases and APIs",
              "Knowledge of automation tools and libraries",
              "Strong debugging and problem-solving skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Developing software using Python and related frameworks",
              "Building APIs and integrating them with databases",
              "Testing and debugging Python applications",
            ],
            location: "Remote",
             isFullTime:false

          },
  {
            title: "Python Developer FullTime ",
            role:"Python-Developer-FullTime",
            skills: [
              "Proficiency in Python programming language",
              "Familiarity with Python frameworks (e.g., Django, Flask)",
              "Understanding of databases and APIs",
              "Knowledge of automation tools and libraries",
              "Strong debugging and problem-solving skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Developing software using Python and related frameworks",
              "Building APIs and integrating them with databases",
              "Testing and debugging Python applications",
            ],
            location: "Remote",
             isFullTime:true

          },

          {
            title: "Data Analytics Intern ",
            role:"Data-Analytics-Intern",
            skills: [
              "Proficiency in data analysis tools (e.g., Excel, SQL, Python)",
              "Knowledge of data visualization tools (e.g., Tableau, Power BI)",
              "Understanding of statistics and data modeling",
              "Strong analytical and problem-solving skills",
              "Ability to interpret large datasets and generate insights",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Analyzing data to provide actionable insights",
              "Creating data visualizations and reports",
              "Collaborating with teams to interpret and use data effectively",
            ],
            location: "Remote",
            isFullTime:false

          },
    {
            title: "Data Analytics FullTime ",
            role:"Data-Analytics-FullTime",
            skills: [
              "Proficiency in data analysis tools (e.g., Excel, SQL, Python)",
              "Knowledge of data visualization tools (e.g., Tableau, Power BI)",
              "Understanding of statistics and data modeling",
              "Strong analytical and problem-solving skills",
              "Ability to interpret large datasets and generate insights",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Analyzing data to provide actionable insights",
              "Creating data visualizations and reports",
              "Collaborating with teams to interpret and use data effectively",
            ],
            location: "Remote",
            isFullTime:true

          },
          {
            title: "Cybersecurity & Ethical Hacking Intern ",
            role:"Cybersecurity-&-Ethical-Hacking-Intern",
            skills: [
              "Understanding of network security principles",
              "Familiarity with ethical hacking tools and techniques",
              "Knowledge of vulnerability assessments and penetration testing",
              "Understanding of security frameworks and compliance",
              "Problem-solving and analytical skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Conducting vulnerability assessments and penetration tests",
              "Implementing security measures to protect data",
              "Collaborating with teams to secure applications and systems",
            ],
            location: "Remote",
            isFullTime:false

          },
  {
            title: "Cybersecurity & Ethical Hacking FullTime ",
            role:"Cybersecurity-&-Ethical-Hacking-FullTime",
            skills: [
              "Understanding of network security principles",
              "Familiarity with ethical hacking tools and techniques",
              "Knowledge of vulnerability assessments and penetration testing",
              "Understanding of security frameworks and compliance",
              "Problem-solving and analytical skills",
            ],
            stipend: "Upto INR 3.5 LPA",
            responsibilities: [
              "Conducting vulnerability assessments and penetration tests",
              "Implementing security measures to protect data",
              "Collaborating with teams to secure applications and systems",
            ],
            location: "Remote",
            isFullTime:true

          },        
        {
            title: "Business Development Intern",
            role:"Business-Development-Intern",
            skills: [
              "Strong communication and negotiation skills",
              "Market research and analysis",
              "Understanding of business development and sales processes",
              "Basic knowledge of CRM tools and business software (e.g., MS Office)",
              "Ability to work independently and within a team",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Identifying new business opportunities",
              "Developing strategies to increase the company’s client base",
              "Improving profitability through innovative business practices",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "Marketing Intern",
            role:"Marketing-Intern",
            skills: [
              "Strong verbal and written communication skills",
              "Familiarity with digital marketing techniques (SEO, SEM, social media)",
              "Analytical skills to interpret marketing metrics",
              "Creativity in developing marketing campaigns",
              "Knowledge of marketing tools like Google Analytics",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Assisting in the creation and execution of marketing campaigns",
              "Supporting brand management activities",
              "Aligning marketing strategies with sales objectives",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "HR Intern",
            role: "HR-Intern",
            skills: [
              "Excellent interpersonal and communication skills",
              "Organizational skills and attention to detail",
              "Basic understanding of HR software and databases (e.g., HRMS)",
              "Ability to manage multiple tasks and projects",
              "Problem-solving and conflict resolution abilities",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Assisting in recruitment and onboarding",
              "Supporting employee engagement activities",
              "Maintaining employee records and HR policy development",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "Operations Intern",
            role:"Operations-Intern",
            skills: [
              "Analytical thinking and problem-solving",
              "Time management and multitasking skills",
              "Process improvement mindset",
              "Familiarity with operations management principles",
              "Proficiency in MS Office, especially Excel",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Optimizing day-to-day operations",
              "Analyzing processes and recommending improvements",
              "Collaborating with multiple departments for efficiency",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "Finance Intern",
            role:"Finance-Intern",
            skills: [
              "Strong analytical and numerical skills",
              "Proficiency in MS Excel and financial software",
              "Basic knowledge of accounting and finance principles",
              "Ability to work under pressure and meet deadlines",
              "High attention to detail",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Preparing financial reports and analyzing financial data",
              "Conducting market research",
              "Providing recommendations for cost reductions and profit improvements",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "Marketing Manager Intern",
            role:"Marketing-Manager-Intern",
            skills: [
              "Strong leadership and project management skills",
              "Proficiency in digital marketing tools (e.g., Google Ads, Facebook Ads)",
              "Knowledge of market segmentation and targeting",
              "Strong analytical skills to measure campaign success",
              "Excellent communication and presentation skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Designing and executing marketing strategies",
              "Driving lead generation and managing marketing budgets",
              "Collaborating with sales teams to align marketing efforts with revenue goals",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "Product Management Intern",
            role:"Product-Management-Intern",
            skills: [
              "Strong analytical and market research skills",
              "Knowledge of Agile and Scrum methodologies",
              "Excellent communication and presentation skills",
              "Ability to work cross-functionally with engineering, marketing, and sales teams",
              "Problem-solving and decision-making skills",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Assisting in defining product strategy",
              "Working with development teams to ensure product success",
              "Conducting market research to meet customer needs",
            ],
            location: "Remote",
            isFullTime:false

          },
          {
            title: "Supply Chain Management Intern",
            role:"Supply-Chain-Management-Intern",
            skills: [
              "Strong analytical and problem-solving skills",
              "Knowledge of supply chain management software (e.g., SAP, Oracle)",
              "Excellent organizational and time management skills",
              "Ability to work with suppliers and negotiate contracts",
              "Familiarity with logistics, warehousing, and distribution",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Planning, implementing, and optimizing supply chain processes",
              "Collaborating with suppliers and logistics partners",
              "Ensuring efficient delivery of goods and services",
            ],
            location: "Remote",
             isFullTime:false

          },
          {
            title: "Strategic Management Intern",
            role:"Strategic-Management-Intern",
            skills: [
              "Strong analytical and strategic thinking skills",
              "Excellent research and data interpretation skills",
              "Familiarity with business models and corporate strategy frameworks",
              "Ability to present insights and recommendations to senior management",
              "Strong collaboration and teamwork abilities",
            ],
            stipend: "Upto INR 5000-15000",
            responsibilities: [
              "Supporting the development and execution of corporate strategy",
              "Researching industry trends and competitive positioning",
              "Identifying growth opportunities for the business",
            ],
            location: "Remote",
            isFullTime:false

          },      
    ];