import React from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box } from '@mui/material';
const Industries = () => {
    const industriesData = [
        {
            id: 1,
            imgSrc: "https://img.freepik.com/premium-photo/nurses-are-caring-elderly-patients-hospital_984354-1798.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "Healthcare",
            description: "We provide advanced healthcare IT consulting solutions, including telemedicine platforms, patient management systems, and AI-powered diagnostic tools."
        },
        {
            id: 2,
            imgSrc: "https://img.freepik.com/free-vector/banking-concept-flat_1284-14619.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "Finance & Banking",
            description: "Our services in the financial industry range from secure online banking applications to fraud detection using AI and machine learning solutions."
        },
        {
            id: 3,
            imgSrc: "https://img.freepik.com/free-vector/worldwide-e-commerce-concept_23-2147657845.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "E-Commerce",
            description: "From building scalable e-commerce platforms to integrating payment gateways, we help businesses reach their customers globally with ease."
        },
        {
            id: 4,
            imgSrc: "https://img.freepik.com/premium-photo/graduation-cap-stack-books-image_669954-49813.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "Education",
            description: "We deliver cutting-edge educational technology solutions, including online learning platforms, virtual classrooms, and AI-powered tutoring systems."
        },
        {
            id: 5,
            imgSrc: "https://img.freepik.com/free-photo/logistics-means-transport-together-with-technological-futuristic-holograms_23-2151662945.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "Logistics & Supply Chain",
            description: "Our custom software solutions streamline logistics operations and provide real-time tracking, inventory management, and optimization tools."
        },
        {
            id: 6,
            imgSrc: "https://img.freepik.com/premium-photo/promotional-sale-retail-store-with-banners-discounts-special-offers_1327465-21642.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "Retail",
            description: "We support retailers with POS systems, mobile commerce apps, and data analytics to enhance customer experiences and boost sales."
        },
        {
            id: 7,
            imgSrc: "https://img.freepik.com/free-photo/assembly-line-production-new-car-automated-welding-car-body-production-line-robotic-arm-car-production-line-is-working_645730-580.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "Automotive",
            description: "We develop automotive software solutions, including IoT-based fleet management systems and connected car technologies."
        },
        {
            id: 8,
            imgSrc: "https://img.freepik.com/premium-photo/smart-city-communication-network-concept-digital-transformation_639785-59956.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid",
            title: "Telecommunications",
            description: "Our telecom solutions include customer relationship management (CRM), billing systems, and network infrastructure management tools."
        }
    ];
    
    
    return (
        <div>
            <Box className={styles.textLoop}>
            Industries
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "We Covers..",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </Box> 
            <div className={styles.container}  >
                {industriesData.map(industry => (
                    <div key={industry.id}  className={styles.industryCard} >
                        <img src={industry.imgSrc} alt={`Image for ${industry.title}`} />
                        <h2>{industry.title}</h2>
                        <p>{industry.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Industries;