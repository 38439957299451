import { Alert, AlertTitle, Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components'


const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`

const ContactInput = styled.input`
  flex: 1;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.text_secondary};
  outline: none;
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  border-radius: 8px;
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary};
  }
    &::placeholder{
       padding-left:12px;
    }
`

const ContactButton = styled.input`
  width: 20%;
  text-decoration: none;
  text-align: center;
  background: hsla(271, 100%, 50%, 1);
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -moz-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%);
  background: -webkit-linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(465, 100%, 50%, 1) 100%);
  padding: 13px 16px;
  margin-top: 2px;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.text_primary};
  font-size: 18px;
  font-weight: 600;  
   @media (max-width: 1230px) {
    width: 50%;
    padding: 13px 16px;
  }
    @media (max-width: 640px) {
   width: 50%;
    padding: 13px 16px;
  }
`
export default function Index() {
  const { role } = useParams();
  const { isfulltime } = useParams();
  const [myYear,setMyYear]=useState(0)
  const [myIntership,setMyIntership]=useState(0)
  const [whereHear,setWhereHear]=useState(0)
  const [isError, setIsError] = useState({
    isNameError: false,
    isEmailError: false,
    isPhoneError: false,
    isCollageError: false,
    isAddressError: false,
    isResumeLinkError: false,
    isDepartmentError: false
  })
  const [submitFormError, setSubmitFormError] = useState({
    success: false,
    loading: false,
    error: false,
    isDataFilled:false
  })
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    college: '',
    department: '',
    year: '',
    position: role,
    intern_duration: '',
    date: new Date().toLocaleDateString(),
    phone: '',
    resume_link: '',
    address: '',
    referal_email_id:'',
    where_hear_job:''
  });
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const year = [1, 2, 3, 4, 5]
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(submitFormError.loading)return;
    if(formData?.name?.length<3 || formData?.email?.length<3 || formData?.college?.length<3 || formData?.department?.length<3 || 
      formData?.year?.length<3 || formData?.resume_link?.length<3 || formData?.phone?.length<3 ||
      formData?.address?.length<3
    ){
      setSubmitFormError({
        ...submitFormError,isDataFilled:true
      })
      return 
    }
    setSubmitFormError({
      ...submitFormError,isDataFilled:false
    })
    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });
      setSubmitFormError({ ...submitFormError, loading: true })
      const scriptURL = isfulltime==="false"?'https://script.google.com/macros/s/AKfycbz4Hr20ao_uBxkOpEd0O8DvoQKUp5bh4t_7Q2xK30QAFDKEjKG4IfFJwYaw-WfMatrz/exec'
      :"https://script.google.com/macros/s/AKfycbz2M3MKPqyh4gNsNrhRBwyWlRaDDgnwfIzjRvvGLV0DKPjb1jFUuHEvB3_rJdNdLGSPtw/exec";
      const res = await fetch(scriptURL, {
        method: 'POST',
        body: data
      });
      setSubmitFormError({ ...submitFormError, loading: false, success: true })
      setMyYear(0)
      setMyIntership(0)
      setFormData({
        name: '',
        email: '',
        college: '',
        department: '',
        year: '',
        intern_duration: '',
        phone: '',
        resume_link: '',
        address: '',
        referal_email_id:''
      })

    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  };
  const handleChange = (e, value) => {
    if (value === "name") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isNameError: true })
      } else {
        setIsError({ ...isError, isNameError: false })
      }
      setFormData({ ...formData, name: e.target.value })
    }else if (value === "department" ) {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isDepartmentError: true })
      } else {
        setIsError({ ...isError, isDepartmentError: false })
      }
      setFormData({ ...formData, department: e.target.value })
    }
     else if (value === "email") {
      if (e.target?.value && e.target.value.match(isValidEmail)) {
        setIsError({ ...isError, isEmailError: false })        
      } else {
        setIsError({ ...isError, isEmailError: true })
      }
      setFormData({ ...formData, email: e.target.value })
    } else if (value === "phoneNumber") {
      if (e.target.value.length === 0 || e.target.value.length <= 9) {
        setIsError({ ...isError, isPhoneError: true })
      } else {
        setIsError({ ...isError, isPhoneError: false })
      }
      setFormData({ ...formData, phone: e.target.value })
    } else if (value === "collage" ) {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isCollageError: true })
      } else {
        setIsError({ ...isError, isCollageError: false })
      }
      setFormData({ ...formData, college: e.target.value })
    } else if (value === "address" ) {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isAddressError: true })
      } else {
        setIsError({ ...isError, isAddressError: false })
      }
      setFormData({ ...formData, address: e.target.value })
    } else if (value === "resumeLink" ) {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isResumeLinkError: true })
      } else {
        setIsError({ ...isError, isResumeLinkError: false })
      }
      setFormData({ ...formData, resume_link: e.target.value })
    } else if (value === "internshipDuration") {
      setMyIntership(e.target.value)
      if (e.target.value === 2) {
        setFormData({ ...formData, intern_duration: "2 Months" })
      } else if (e.target.value === 3) {
        setFormData({ ...formData, intern_duration: "3 Months" })
      } else if (e.target.value === 6) {
        setFormData({ ...formData, intern_duration: "6 Months" })
      }
    } else if (value === "collageYear") {
      setMyYear(e.target.value)
      if (e.target.value === 5) {
        setFormData({ ...formData, year: "pass out" })
      }else if (e.target.value === 6) {
        setFormData({ ...formData, year: "fresher" })
      }
       else if (e.target.value === 1) {
        setFormData({ ...formData, year: "1st Year" })
      } else if (e.target.value === 2) {
        setFormData({ ...formData, year: "2nd Year" })
      } else if (e.target.value === 3) {
        setFormData({ ...formData, year: "3rd Year" })
      } else if (e.target.value === 4) {
        setFormData({ ...formData, year: "4th Year" })
      }
    }else if(value==="referalEmailId"){
        setFormData({ ...formData, referal_email_id: e.target.value})
    } else if (value === "whereHear") {
      setWhereHear(e.target.value)
      if (e.target.value === 1) {
        setFormData({ ...formData, where_hear_job: "Linked IN" })
      } else if (e.target.value === 2) {
        setFormData({ ...formData, where_hear_job: "Refferal" })
      } else if (e.target.value === 3) {
        setFormData({ ...formData, where_hear_job: "College Freind" })
      } else if (e.target.value === 4) {
        setFormData({ ...formData, where_hear_job: "Career Page" })
      } else if (e.target.value === 5) {
        setFormData({ ...formData, where_hear_job: "Job Sites" })
      }
      else if (e.target.value === 6) {
        setFormData({ ...formData, where_hear_job: "Social Media" })
      }
    }

  }
  return (
    <div style={{ textAlign: "center" }} >
      <div style={{ marginTop: "30px" }}>
        <span style={{ margin: "auto", fontSize: "30px" }}>{role}</span>
      </div>

      <Box style={{ display: "flex", justifyContent: "center" }}>
        <ContactForm >
          <ContactTitle>Application Form </ContactTitle>
          <TextField
            error={isError?.isNameError}
            id="standard-error-helper-text"
            label="Name*"
            helperText={isError?.isNameError && "Pls enter your name"}
            variant="standard"
            value={formData?.name}
            onChange={(e) => handleChange(e, "name")}
          />
          <TextField
            error={isError?.isEmailError}
            id="standard-error-helper-text"
            label="Email*"
            helperText={isError?.isEmailError && "Pls enter validate email"}
            variant="standard"
            value={formData?.email}
            onChange={(e) => handleChange(e, "email")}
          />
          <TextField
            error={isError?.isPhoneError}
            id="standard-error-helper-text"
            label="Phone Number*"
            helperText={isError?.isPhoneError && "Pls enter your valid number"}
            variant="standard"
            value={formData?.phone}
            onChange={(e) => handleChange(e, "phoneNumber")}
          />
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              error={isError?.isCollageError}
              id="standard-error-helper-text"
              label="College*"
              helperText={isError?.isCollageError && "Pls enter your collage"}
              variant="standard"
              value={formData?.college}
              onChange={(e) => handleChange(e, "collage")}
              style={{ width: "70%", marginTop: "8px" }}
            />
            {isfulltime==="false"?
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-standard-label">Select Year</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={myYear}
                label="Year*"
                onChange={(e) => handleChange(e, "collageYear")}
              >            
                  <MenuItem value={1}>1st year</MenuItem>
                  <MenuItem value={2}>2nd year</MenuItem>
                  <MenuItem value={3}>3rd year</MenuItem>
                  <MenuItem value={4}>4th year</MenuItem>
                  <MenuItem value={5}>pass out </MenuItem>
              </Select>
            </FormControl>:
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
              <InputLabel id="demo-simple-select-standard-label">Select Year</InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={myYear}
                label="Year*"
                onChange={(e) => handleChange(e, "collageYear")}
              >  
                  <MenuItem value={4}>4th year</MenuItem>
                  <MenuItem value={6}>fresher</MenuItem>
                  <MenuItem value={5}>pass out </MenuItem>
              </Select>
            </FormControl>
            }
          </Box>
          <TextField
            error={isError?.isDepartmentError}
            id="standard-error-helper-text"
            label="College Degree (ex: Btech/MCA/BCA - CS)*"
            helperText={isError?.isDepartmentError && "Pls enter your engineering branch"}
            variant="standard"
            value={formData?.department}
            onChange={(e) => handleChange(e, "department")}
          />
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              error={isError?.isResumeLinkError}
              id="standard-error-helper-text"
              label="Resume Link*"
              helperText={isError?.isResumeLinkError && "Pls enter your resume link"}
              variant="standard"
              value={formData?.resume_link}
              onChange={(e) => handleChange(e, "resumeLink")}
              style={{ width: isfulltime === "true"?"100%":"70%", marginTop: "8px" }}
            />
            {
              isfulltime === "false" && <>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label">Internship Duration</InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={myIntership}
                    label="Year*"
                    onChange={(e) => handleChange(e, "internshipDuration")}
                  >
                    <MenuItem value={2}>2 Months</MenuItem>
                    <MenuItem value={3}>3 Months</MenuItem>
                    <MenuItem value={6}>6 Months</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
          </Box>

          <TextField
            id="standard-error-helper-text"
            label="Referral Email ID"
            variant="standard"
            value={formData?.referal_email_id}
            onChange={(e) => handleChange(e, "referalEmailId")}
          />
          <TextField
            error={isError?.isAddressError}
            id="standard-error-helper-text"
            label="Address(Aadhaar Card)*"
            helperText={isError?.isAddressError && "Pls enter your address"}
            variant="standard"
            value={formData?.address}
            onChange={(e) => handleChange(e, "address")}
          />
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Where did you hear?*</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          label="Where did you hear?*"
          value={whereHear}
          onChange={(e) => handleChange(e, "whereHear")}
        >
          <MenuItem value={1}>Linked IN</MenuItem>
          <MenuItem value={2}>Refferal</MenuItem>
          <MenuItem value={3}>College Freind</MenuItem>
          <MenuItem value={4}>Career Page</MenuItem>
          <MenuItem value={5}>Job Sites</MenuItem>
          <MenuItem value={6}>Social Media</MenuItem>
        </Select>
      </FormControl>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <ContactButton type="submit" value="Submit" onClick={(e) => handleSubmit(e)} style={{ background: submitFormError.loading && "grey", color:submitFormError.loading &&  'white' }} />
          </Box>
          {
            submitFormError.success &&
              <Alert severity="success" color="success">
                Your Application has been submitted successfully
              </Alert>
          }
          {
            submitFormError.error &&
            <Alert severity="error" color="error">
            Something Wrong
          </Alert>
          }
          {
            submitFormError.isDataFilled &&
            <Alert severity="error" color="error">
           Please fill all data
          </Alert>
          }
              

        </ContactForm>

      </Box>
    </div>
  )
}
