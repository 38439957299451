
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink } from './NavbarStyledComponent'
import { FaBars } from 'react-icons/fa';
import { useTheme } from 'styled-components';
import myImage from './logo.png';
import { PopupWidget } from 'react-calendly';
import { useNavigate } from 'react-router-dom';
import { forwardRef, useContext, useRef, useState } from 'react';
import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import styled from 'styled-components'
import axios from 'axios';
import { AuthContex } from '../../contex/AuthContex';
import { backendApi } from "../../constant"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ContactForm = styled.form`
  width: 95%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.card};
  padding: 32px;
  border-radius: 16px;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  margin-top: 28px;
  gap: 12px;
`

const ContactTitle = styled.div`
  font-size: 24px;
  margin-bottom: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
`
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLogin, setisLogin } = useContext(AuthContex)
  const [openModelSignIn, setOpenModelSignIn] = useState(false)
  const [openModelLogIn, setOpenModelLogIn] = useState(false)
  const [logOut, setLogOut] = useState(false)
  const [errorMsg, setErrorMsg] = useState("Something Went Wrong")
  const navigate = useNavigate()
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    cPassword: ""
  })
  const [isError, setIsError] = useState({
    isNameError: false,
    isEmailError: false,
    isPasswordError: false,
    isCPasswordError: false,
  })
  const [submitFormError, setSubmitFormError] = useState({
    success: false,
    loading: false,
    error: false,
    isDataFilled: false
  })
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const theme = useTheme();
  const calendlyRef = useRef();
  const handleClick = (value) => {
    setIsOpen(!isOpen);
  };
  console.log({ isLogin })
  const handleLogInModal = () => {
    setOpenModelLogIn(true)
    setForm({
      name: "",
      email: "",
      password: "",
      cPassword: ""
    })
    setIsError({
      isNameError: false,
      isEmailError: false,
      isPasswordError: false,
      isCPasswordError: false,
    })
    setSubmitFormError({
      success: false,
      loading: false,
      error: false,
      isDataFilled: false
    })
  }
  const handleSignInModal = () => {
    setOpenModelSignIn(true)
    setOpenModelLogIn(false)
    setForm({
      name: "",
      email: "",
      password: "",
      cPassword: ""
    })
    setIsError({
      isNameError: false,
      isEmailError: false,
      isPasswordError: false,
      isCPasswordError: false,
    })
    setSubmitFormError({
      success: false,
      loading: false,
      error: false,
      isDataFilled: false
    })
  }
  const handleClose = () => {
    setOpenModelSignIn(false)
    setOpenModelLogIn(false)
  }
  const handleChange = (e, value) => {
    if (value === "name") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isNameError: true })
      } else {
        setIsError({ ...isError, isNameError: false })
      }
      setForm({ ...form, name: e.target.value })
    } else if (value === "email") {
      if (e.target?.value && e.target.value.match(isValidEmail)) {
        setIsError({ ...isError, isEmailError: false })
      } else {
        setIsError({ ...isError, isEmailError: true })
      }
      setForm({ ...form, email: e.target.value })
    } else if (value === "password") {
      if (e.target.value.length === 0 || e.target.value.length <= 3) {
        setIsError({ ...isError, isPasswordError: true })
      } else {
        setIsError({ ...isError, isPasswordError: false })
      }
      setForm({ ...form, password: e.target.value })
    } else if (value === "cpassword") {
      if ((form?.password !== e.target.value)) {
        setIsError({ ...isError, isCPasswordError: true })
      } else {
        setIsError({ ...isError, isCPasswordError: false })
      }
      setForm({ ...form, cPassword: e.target.value })
    }
  }
  const handleSubmit = async (e) => {
    if (submitFormError.loading) return;
    if (isError?.isNameError || isError?.isEmailError || isError?.isPasswordError || isError?.isCPasswordError) {
      setSubmitFormError({ ...submitFormError, loading: false, isDataFilled: true })
      return
    }
    if (form?.email.length === 0 || form.password.length === 0) {
      setSubmitFormError({ ...submitFormError, loading: false, isDataFilled: true })
      if ((form.name.length === 0 || form.cPassword.length === 0) && openModelSignIn) {
        setSubmitFormError({ ...submitFormError, loading: false, isDataFilled: true })
      }
      return
    }
    setSubmitFormError({ ...submitFormError, loading: true })
    try {
      const scriptURL = openModelSignIn ? `${backendApi}/user/signin` : `${backendApi}/user/login`;
      const { data } = await axios.post(scriptURL, form)
      if (data?.success) {
        if (openModelLogIn) {
          localStorage.setItem("token", JSON.stringify(data?.token))
          setisLogin(true)
          setOpenModelLogIn(false)
        } else {
          setOpenModelSignIn(false)
          setOpenModelLogIn(true)
        }
        setSubmitFormError({ ...submitFormError, loading: false, success: true })
        setErrorMsg(data?.msg)
      } else {
        setErrorMsg(data?.msg)
        setSubmitFormError({ ...submitFormError, loading: false, error: true })
      }
    } catch (error) {
      setSubmitFormError({ ...submitFormError, loading: false, error: true })
    }
  };
  const handleLogInAndLogOut = () => {
    if (!isLogin) {
      handleLogInModal()
    } else {
      setLogOut(true)
    }
  }
  const handleLogOut = () => {
    localStorage.removeItem("token")
    setisLogin(false)
    setLogOut(false)
  }
  return (<>
    <Modal
      open={openModelLogIn}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: "50vw" }} >
        <ContactForm >
          <ContactTitle>Log-In</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Email"
            variant="standard"
            value={form?.email}
            error={isError?.isEmailError}
            helperText={isError?.isPasswordError && "Pls enter validate email"}
            onChange={(e) => handleChange(e, "email")}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Password"
            variant="standard"
            type="password"
            value={form?.password}
            error={isError?.isPasswordError}
            helperText={isError?.isPasswordError && "Pls enter validate password"}
            onChange={(e) => handleChange(e, "password")}
          />
          <Button variant="outlined" onClick={(e) => handleSubmit()}>Log In</Button>
          <Button variant="outlined" onClick={() => handleSignInModal()}>Go To Sign In</Button>
        </ContactForm>
        {
          submitFormError.success &&
          <Alert severity="success" color="success">
            Your Application has been submitted successfully
          </Alert>
        }
        {
          submitFormError.error &&
          <Alert severity="error" color="error">
            {errorMsg}
          </Alert>
        }
        {
          submitFormError.isDataFilled &&
          <Alert severity="error" color="error">
            Please fill proper data
          </Alert>
        }
      </Box>
    </Modal>
    <Modal
      open={openModelSignIn}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: "50vw" }} >
        <ContactForm >
          <ContactTitle>Sign-In</ContactTitle>
          <TextField
            id="standard-error-helper-text"
            placeholder="Name"
            variant="standard"
            value={form?.name}
            error={isError?.isNameError}
            helperText={isError?.isPasswordError && "Pls enter validate name"}
            onChange={(e) => handleChange(e, "name")}
          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Email"
            variant="standard"
            value={form?.email}
            error={isError?.isEmailError}
            helperText={isError?.isPasswordError && "Pls enter validate email"}
            onChange={(e) => handleChange(e, "email")}

          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Password"
            variant="standard"
            type="password"
            value={form?.password}
            error={isError?.isPasswordError}
            helperText={isError?.isPasswordError && "Pls enter validate password"}
            onChange={(e) => handleChange(e, "password")}

          />
          <TextField
            id="standard-error-helper-text"
            placeholder="Comferm Password"
            variant="standard"
            type="password"
            value={form?.cPassword}
            error={isError?.isCPasswordError}
            helperText={isError?.isCPasswordError && "password not match"}
            onChange={(e) => handleChange(e, "cpassword")}
          />
          <Button variant="outlined" onClick={(e) => handleSubmit()}>Sign In</Button>
          {
            submitFormError.success &&
            <Alert severity="success" color="success">
              Your Application has been submitted successfully
            </Alert>
          }
          {
            submitFormError.error &&
            <Alert severity="error" color="error">
              {errorMsg}
            </Alert>
          }
          {
            submitFormError.isDataFilled &&
            <Alert severity="error" color="error">
              Please fill proper data
            </Alert>
          }
        </ContactForm>
      </Box>
    </Modal>
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <span style={{ display: 'flex', alignItems: 'center', color: 'white', marginBottom: '20;', cursor: 'pointer' }} >
            <img src={myImage} alt="Logo" style={{ width: '5rem', height: '5rem' }} />
            <Span>Aminurmus Technology</Span>
          </span>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => setIsOpen(!isOpen)} />
        </MobileIcon>
        <NavItems>
          <NavLink href="/" >Home</NavLink>
          <NavLink href="/services">Services</NavLink>
          <NavLink href="/career">Careers</NavLink>
          <NavLink href="/industries">Industries</NavLink>
          <NavLink href="/blog">Blog</NavLink>
          <NavLink href="/" >About Us</NavLink>
        </NavItems>
        <ButtonContainer>
        </ButtonContainer>
        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <MobileLink href="/" onClick={() => handleClick('/')}>Home</MobileLink>
            <MobileLink href="/services" onClick={() => handleClick('/services')}>Services</MobileLink>
            <MobileLink href="/career" onClick={() => handleClick('/career')}>Careers</MobileLink>
            <MobileLink href="/industries" onClick={() => handleClick('/industries')}>Industries</MobileLink>
            <MobileLink href="/blog" onClick={() => handleClick('/blog')}>Blog</MobileLink>
            <MobileLink href="/" onClick={() => handleClick('/')}>About Us</MobileLink>
          </MobileMenu>
        )}
      </NavbarContainer>
      <CalendlyComponent ref={calendlyRef} />
      <Modal
        open={logOut}
        onClose={() => setLogOut(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Log-Out
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure want to Log-Out
          </Typography>
          <Button variant="outlined" sx={{ mt: 2 }} onClick={() => handleLogOut()} >Comferm</Button>

        </Box>
      </Modal>
    </Nav>
  </>
  );
};
const CalendlyComponent = forwardRef((_, ref) => (
  <div className="popup-widget">
    <PopupWidget
      url="https://calendly.com/aminurmustechnology/30min"
      rootElement={document.getElementById('root')}
      text="Let's Connect"
      textColor="#ffffff"
      color="-webkit-linear-gradient(225deg, hsla(259, 100%, 50%, 1) 0%, hsla(489, 100%, 50%, 1) 100%)"
    />
    <button ref={ref} style={{ display: 'none' }}>Trigger Calendly</button>
  </div>
));
export default Navbar;