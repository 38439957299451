import React from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box } from '@mui/material';
const Blog = () => {
  const blogPosts = [
    {
      title: 'Revolutionizing Web Development with MERN Stack',
      author: 'Sarah Ali',
      date: 'August 10, 2024',
      description: 'The MERN stack is a powerful suite of technologies used to build modern web applications. Here at Aminurmus Technology, our team of experts is constantly refining and implementing best practices in full-stack development to stay ahead of the curve...',
      imgSrc: 'https://plus.unsplash.com/premium_photo-1663050633633-2856e875dcc7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      title: 'Android/iOS App Development: The Future of Mobile Solutions',
      author: 'John Smith',
      date: 'July 29, 2024',
      description: 'Mobile app development continues to grow as more businesses realize the importance of offering mobile-friendly experiences. Our expertise in Android and iOS app development ensures that we create seamless and user-friendly mobile apps...',
      imgSrc: 'https://plus.unsplash.com/premium_photo-1661326248013-3107a4b2bd91?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      title: 'How AI & ML Are Transforming Business Strategies',
      author: 'Emily Wang',
      date: 'August 18, 2024',
      description: 'Artificial intelligence and machine learning are changing how companies operate, offering new ways to solve complex problems. Aminurmus Technology provides advanced AI/ML solutions to empower your business with data-driven insights...',
      imgSrc: 'https://img.freepik.com/free-photo/ai-chip-artificial-intelligence-future-technology-innovation_53876-129780.jpg?w=740&t=st=1725534390~exp=1725534990~hmac=8f35c2df710bf01164f234c5539efc0a767d0734fc0f5fe45bc8262de1f4f2db'
    },
    {
      title: 'Data Science: The Key to Unlocking Business Potential',
      author: 'Michael Brown',
      date: 'July 15, 2024',
      description: 'Data is the new oil, and understanding how to extract valuable insights from it is crucial for businesses. Our data science team is proficient in leveraging data to provide actionable insights that drive business growth and innovation...',
      imgSrc: 'https://img.freepik.com/premium-photo/data-scientist-business-manager-collaborating-holographic-data-dashboard_1222282-3445.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid'
    },
    {
      title: 'Enhancing UI/UX Design for a Better User Experience',
      author: 'Rahul Kumar',
      date: 'August 5, 2024',
      description: 'A great user experience (UX) and user interface (UI) design are essential for the success of any digital product. At Aminurmus Technology, we focus on creating intuitive designs that improve usability and enhance customer satisfaction...',
      imgSrc: 'https://img.freepik.com/premium-photo/close-up-ux-developer-ui-designer-brainstorming-about-mobile-app-interface-wireframe-design-table-with-customer-breif-color-code-modern-officecreative-digital-development-agency_265022-55879.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid'
    },
    {
      title: 'SEO Optimization: Driving Organic Traffic to Your Website',
      author: 'David Lee',
      date: 'August 3, 2024',
      description: 'Search Engine Optimization (SEO) is a critical aspect of digital marketing. Our experts help businesses boost their online presence by implementing effective SEO strategies to increase visibility and drive organic traffic to their websites...',
      imgSrc: 'https://img.freepik.com/free-photo/searching-engine-optimizing-seo-browsing-concept_53876-64993.jpg?uid=R160194662&ga=GA1.1.347569992.1706451951&semt=ais_hybrid'
    }
  ];

  return (
    <div>
        <Box className={styles.textLoop}>
        Technology
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "Blogs..",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </Box>  

      <div  className={styles.container}>
        {blogPosts.map((post, index) => (
          <div  className={styles.blogPost} key={index}>
            <img src={post.imgSrc} alt={post.title} />
            <h2>{post.title}</h2>
            <div className={styles.blogMeta}>
              <span>By {post.author} | {post.date}</span>
            </div>
            <p>{post.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
