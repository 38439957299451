import React from 'react';
import styles from './myStyles.module.css'; 
import Typewriter from 'typewriter-effect';
import { Box } from '@mui/material';
const Blog = () => {
  const blogPosts = [   
    {
        title: "Web Development",
        description: "Build dynamic and responsive websites with cutting-edge technologies like HTML, CSS, JavaScript, and frameworks like React and Angular.",
        imgSrc: "https://img.freepik.com/free-photo/programming-background-collage_23-2149901791.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "Mobile App Development",
        description: "Develop Android and iOS mobile applications with seamless performance using native and hybrid technologies.",
        imgSrc: "https://img.freepik.com/premium-photo/app-developer-meeting-discussing-smartphone-screen-prototype-uiux_89286-11851.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "AI & Machine Learning",
        description: "Leverage the power of AI/ML for predictive analytics, intelligent automation, and advanced problem-solving in various sectors.",
        imgSrc: "https://img.freepik.com/premium-photo/leveraging-ai-technology-enhanced-work-efficiency-problemsolving_1297251-6566.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"

      },
    {
        title: "Cloud Computing",
        description: "Provide scalable cloud solutions, including AWS, Azure, and Google Cloud services, to improve data accessibility and security.",
        imgSrc: "https://img.freepik.com/free-photo/saas-concept-collage_23-2149399290.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "UI/UX Design",
        description: "Design intuitive and visually appealing user interfaces to enhance user experience across web and mobile platforms.",
        imgSrc: "https://img.freepik.com/premium-photo/cell-phone-with-words-were-best-thing-world_972324-104071.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "Cybersecurity",
        description: "Ensure data protection with advanced cybersecurity services, including vulnerability assessments and penetration testing.",
        imgSrc: "https://img.freepik.com/premium-photo/group-people-working-lab-with-map-world-wall_1134661-41418.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "Data Analytics",
        description: "Analyze and interpret complex data to generate actionable insights, helping businesses make informed decisions.",
        imgSrc: "https://img.freepik.com/premium-photo/clear-distribution-image-highlighting-delivery-network_1199394-106750.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "Software Development",
        description: "Develop custom software solutions tailored to meet your business needs, enhancing productivity and efficiency.",
        imgSrc: "https://img.freepik.com/free-photo/html-css-collage-concept-with-person_23-2150062008.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "SEO Optimization",
        description: "Improve your website’s visibility and ranking on search engines with our comprehensive SEO services.",
        imgSrc: "https://img.freepik.com/premium-photo/3d-seo-optimization-promotion-web-browser-digital-marketing-concept_1121250-104717.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      },
    {
        title: "Blockchain Development",
        description: "Implement blockchain technology to enhance security, transparency, and efficiency in business operations.",
        imgSrc: "https://img.freepik.com/premium-photo/blockchain-technology-background-with-cryptocurrency-fintech-concept_269655-22297.jpg?uid=R154986372&ga=GA1.1.1902770949.1720603423&semt=ais_hybrid"
      }
  ];

  return (
    <div>
        <Box className={styles.textLoop}>
        Services
            <span className={styles.span}>
          <Typewriter
              options={{
                  strings: "We Provide..",
                  autoStart: true,
                  loop: true,
              }}
          />
            </span>
        </Box>  

      <div  className={styles.container}>
        {blogPosts.map((post, index) => (
          <div  className={styles.blogPost} key={index}>
            <img src={post.imgSrc} alt={post.title} />
            <h2>{post.title}</h2>
            <p>{post.description}</p>
            <a  className={styles.btnLink} href="mailto:info@aminurmus.com" target="_blank" style={{width:"115px",height:'40px'}}>Request Service</a>
     
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;